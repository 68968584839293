<template>
    <div class="site-signup">
        <edit :fs="siteSignupFields" @saved="saved" apiSave="s5/signup" ref="edit" title="Signup Site">
            <template slot="header">
                <h2>Get A Vendor Website Only $19.99 / A Month</h2>
                <div>
                    <span>7-Day Free Trial</span>
                </div>
                <!-- <div>
                    <span>Already has an Account ?</span>
                    <a href="https://eztimerental.site/login/auth">Sign In</a>
                </div>-->
            </template>
            <template slot="domain" slot-scope="{f,model}">
                <md-input-container>
                    <label for>{{f.heading}}</label>
                    <md-button class="normal-button">https://</md-button>
                    <md-input :data-vv-as="f.heading" :data-vv-name="f.data" :data-vv-rules="f.validate" :value="domainInnerValue" @change="handleDomainChange(model,$event)" placeholder="domain" v-validate></md-input>
                    <md-button class="normal-button">.eztimerental.site</md-button>
                    <span class="md-error" v-show="errors.has(f.data)">{{ errors.first(f.data) }}</span>
                </md-input-container>
            </template>
        </edit>
    </div>
</template>
<script>
import Edit from "@/components/Edit";
import { mapGetters } from "vuex";
import { siteSignupFields } from "@/config";
export default {
    components: {
        Edit
    },
    data() {
        return {
            domainInnerValue: ""
        };
    },
    computed: {
        ...mapGetters({
            user: "user/user",
            hasRole: "user/hasRole"
        }),
        siteSignupFields() {
            let fs = JSON.parse(JSON.stringify(siteSignupFields));
            if (this.hasRole("vendor")) {
                return fs;
            } else {
                return fs.map(f => {
                    if (f.data == "email") {
                        f.type = "readonly";
                    }
                    return f;
                });
            }
        }
    },
    methods: {
        handleDomainChange(model, val) {
            model["domain"] = `${val}.eztimerental.site`;
        },
        saved() {
            // this.$router.push("/admin/sites");
            this.$store.commit("setMessage", "Site Sign Up Scuccess");
            this.$router.push("/admin/sites/signin");
        },
        setDefaultValue() {
            let newRecord = this.$refs["edit"].newRecord;
            if (this.user) {
                this.$set(newRecord, "email", this.user.email);
                newRecord.email = this.user.email;
                newRecord.firstName = this.user.name.split(" ")[0];
                newRecord.lastName = this.user.name.split(" ")[1] || "_";
                if (this.user.vendor && this.user.vendor.business) {
                    newRecord.orgName = this.user.vendor.business;
                }
            }
        }
    },
    mounted() {
        setTimeout(() => {
            this.setDefaultValue();
        }, 0);
    }
};
</script>
<style lang="less">
.site-signup {
    max-width: 1000px;
    margin: auto;
    .normal-button {
        text-transform: none;
        background: #efefef;
    }
    .md-input-container {
        > button {
            text-transform: normal;
        }
    }
}
</style>

